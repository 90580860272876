// import Button from '@material-ui/core/Button';
import { Datagrid, Filter, List, NumberField, TextField, TextInput } from 'react-admin';

import { Button, TopToolbar } from 'react-admin';
import { cancelOrdercustomAction } from './buttonActions/setOrderCanceledAction';
import { recievedOrderStatus } from './buttonActions/setOrderReceivedStatusAction';
import { flagPodOrderPaymentDefaultCustomAction } from './buttonActions/setPodOrderPaymentIsInDefaultAction';


const SearchFilter = props => (
  <Filter {...props}>
     <TextInput label="Seller User Id" source="sellerUserId" alwaysOn />
  </Filter>
)

export const ProductsList = props => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
    <TextField source="sellerPublicProfile.userId" label="Seller Id" link={false} />
      <TextField source="id" label="Product Id" link={false} />
      <TextField source="productName" label="Product Name" />
      <TextField source="minimumQuantity" label="Minimum Quantity" />
      <TextField source="maximumQuantity" label="Maximum Quantity" />
      <TextField source="category.name" label= "Category Name"/>
      <TextField source="brand.name" label= "Brand Name"/> 
      <NumberField source="price" label= "Price"/>
      <TextField source="locationState" label= "Location"/>
       <TextField source="sellerPublicProfile.firstName" label="Seller First Name"/>
      
      <TextField source="sellerPublicProfile.lastName" label="Seller Last Name"/>

    </Datagrid>
  </List>
);

export const CancelOrderButton = props => {
  return (
    <TopToolbar>
      <Button label="Cancel" color="primary" onClick={
        (e) => {
          cancelOrdercustomAction(props.record.uuid, window.location.href)
        e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};

export const FlagPodOrderAsInDefaultButton = props => {
  return (
    <TopToolbar>
      <Button label="Buyer cant pay" color="primary" onClick={
        (e) => {
          flagPodOrderPaymentDefaultCustomAction(props.record.uuid, window.location.href)
          e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};

export const FlagReceivedOrderStatusButton = props => {
  return (
    <TopToolbar>
      <Button label="Order Received" color="primary" onClick={
        (e) => {
          recievedOrderStatus(props.record.uuid, window.location.href)
        e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};
