import {
  Admin, BooleanField, Create, Datagrid, Filter, List, RadioButtonGroupInput, Resource, SimpleForm, TextField, TextInput
} from 'react-admin';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import authProvider from './authProvider';
import Dashboard from './Dashboard';
import dataProvider from './dataProvider';

import { AddDeliveryLocation } from './addDeliveryLocation';
import { AddItemToProcurementInvoice } from './addItemToInvoice';
import { AddProduct } from './addProduct';
import { AffiliatesCreate, AffiliatesList } from './affiliates';
import { BrandsList } from './brands';
import { CategoriesList } from './categories';
import { CreateOrder } from './createOrder';
import { CreateQuoteRequest } from './createquoterequest';
import { DeliveryLocationList } from './deliverylocation';
import { deliveryRequestFeeList } from './deliveryRequestFee';
import { EditDeliveryRequestFee } from './deliveryRequestFeeEdit';
import { EditCategory } from './editCategory';
import { EditPriceMatrix } from './editPriceMatrix';
import { EnableCStoreUserButton } from './EnableCstoreUser';
import { FinancialTransctionsCreate, FinancialTransctionsList } from './financialtransactions';
import { EditOrder } from './orderEdit';
import { OrdersList } from './orders';
import { PriceMatrixList } from './priceMatrices';
import { procurementList } from './procurements';
import { ProductsList } from './products';
import { PromotionCreate, PromotionsList } from './promotions';
import { qouteRequestList } from './qouteRequests';
import { bankDetailsChangeList } from './requestBankDetailsChange';
import { RespondToQouteRequestByAdmin } from './respondtoqouterequest';
import { WalletToWalletTransfersCreate, WalletToWalletTransfersList } from './walletToWalletTransfer';

const UserSearchFilter = props => (
  <Filter {...props}>
    <TextInput label="Search with User Id" source="userId" alwaysOn />
    <TextInput label="Search with Phone Number" source="phoneNumber" alwaysOn />
  </Filter>
)

export const UserList = props => (
  <List {...props} filters={<UserSearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="userId" label = "User ID" />
      <TextField source="accountId" label= "Account ID"/>
      <TextField source="firstName" label = "First Name" />
      <TextField source="lastName" label = "Last Name" />
      <TextField source= "msisdn" label = "Phone Number" />
      <BooleanField source="isSeller" label = "Can Sell" />
      <BooleanField source="isCooperate" label = "Cooperate" />

      <BooleanField source="isOnProductLease" label = "Product Lease Enabled"  />
      <TextField source="walletCurrency" label = "Wallet Currency" />
      <TextField source="walletBalanceMajor" label = "Wallet Balance" />
      <EnableCStoreUserButton {...props}  label="Enable C Store" />
      {/* <EnableAccountPlpButton {...props} /> */}
      {/* <ImageField source="photo.url" label= "Photo" /> */}
    </Datagrid>
  </List>
);

const App = () => (
  <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider}>
    <Resource name="users" list={UserList} create={userCreate} />
    <Resource name="products" list={ProductsList} create={AddProduct} />
    <Resource name="affiliates" list={AffiliatesList} create={AffiliatesCreate} />
    {/* <Resource name="productleases" list={ProductleaseList} edit={ProductleaseEdit} create={ProductleaseCreate} /> */}

    <Resource name="orders" list={OrdersList}  edit={EditOrder} create={CreateOrder} />
    <Resource name="delivery locations" list={DeliveryLocationList}  create={AddDeliveryLocation} />
    <Resource name="price matrices" list={PriceMatrixList} edit={EditPriceMatrix}/>


    <Resource name="wallet to wallet transfer" list={WalletToWalletTransfersList} create={WalletToWalletTransfersCreate} />
    <Resource name="financial transactions" list={FinancialTransctionsList} create={FinancialTransctionsCreate} />
    <Resource name="categories" list={CategoriesList} edit={EditCategory} />
    <Resource name="brands" list={BrandsList} />
    <Resource name="promotions" list={PromotionsList} create={PromotionCreate} />
    <Resource name="delivery requests" list={deliveryRequestFeeList} edit={EditDeliveryRequestFee} />
    <Resource name="procurements" list={procurementList} edit={AddItemToProcurementInvoice} />
    <Resource name="quote request" list={qouteRequestList} create={CreateQuoteRequest} edit={RespondToQouteRequestByAdmin} />
    <Resource name="change bank details" list={bankDetailsChangeList} label='Change Bank Details'  />
  </Admin>
);

 const userCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" label="First Name" />
      <TextInput source="lastName" label="Last Name" />
      <TextInput source="phoneNumber" label="Phone Number" />
      <TextInput source="emailAddress" label="Email Address" />
      <RadioButtonGroupInput source="isOMA" choices={[
    { id: 'true', name: 'true' },
    { id: 'false', name: 'false' },
    ]} />
    </SimpleForm>
  </Create>
);

export default App;
