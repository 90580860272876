import logo from './logo.svg';
import './App.css';
import { 
  Admin, Resource, ListGuesser, List, Datagrid, ReferenceField, 
  TextField, NumberField, BooleanField, DateField, UrlField, ImageField, EditButton,
  Edit, SimpleForm, TextInput, SelectInput, ReferenceInput, DateTimeInput, BooleanInput,
  Create, Filter, NumberInput
} from 'react-admin';


export const CategoriesList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" label= "Category Name"/>
      <TextField source="settings.cinderbuildProfiltMargin.amountMajor" label= "CinderBuild Mergin"/>

      <TextField source="uuid" label= "Category UUID"/>

      <DateField source="createdAt" />
    </Datagrid>
  </List>
);


