import {
  Create, NumberInput, RadioButtonGroupInput, SaveButton, SimpleForm, Toolbar
} from 'react-admin';

import { CreateOrderByAdmin } from './buttonActions/createOrderAction';

export const CreateOrder = props => {
  const CustomToolbar = (customToolbarProps) => {
    return (
      <Toolbar>
        <SaveButton label="Submit" handleSubmitWithRedirect={
          (e) => {
            
            const userId = new Number(document.getElementsByName("userId")[0].value)
            const productId = new Number(document.getElementsByName("productId")[0].value)
            const unitPrice = new Number(document.getElementsByName("unitPrice")[0].value)
            const quantity = new Number(document.getElementsByName("quantity")[0].value)
            const orderPaymentVariant = document.getElementsByName("orderPaymentVariant")[0].value
            const orderReceiveType = document.getElementsByName("orderReceiveType")[0].value
            const deliveryAddressId = new Number(document.getElementsByName("deliveryAddressId")[0].value)
            
            CreateOrderByAdmin(userId, productId, quantity, unitPrice, orderPaymentVariant, orderReceiveType, deliveryAddressId, window.location.href)
          }
        } />
      </Toolbar>
    )
  };


  return (
    <Create {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
      <NumberInput source="userId" label="User id"/>
      <NumberInput source="productId" label="Product Id"/>
        <NumberInput source="quantity" label="Quantity " />
        <NumberInput source="unitPrice" label="Unit Price" />
        <RadioButtonGroupInput source="orderPaymentVariant" choices={[
          { id: 'PAY_ON_DELIVERY', name: 'PAY ON DELIVERY' },
          { id: 'WALLET', name: 'WALLET' },
          ]} />
           <RadioButtonGroupInput source="orderReceiveType" choices={[
          { id: 'DELIVERY', name: 'DELIVERY' },
          ]} />
        <NumberInput source="deliveryAddressId" label="Delivery Address Id" />
      </SimpleForm>
    </Create>
  )
}
