// import Button from '@material-ui/core/Button';
import {
  Datagrid, Filter, List, NumberField, ReferenceField,
  TextField, TextInput
} from 'react-admin';

import { Button, TopToolbar } from 'react-admin';
import { cancelOrdercustomAction } from './buttonActions/setOrderCanceledAction';
import { recievedOrderStatus } from './buttonActions/setOrderReceivedStatusAction';
import { flagPodOrderPaymentDefaultCustomAction } from './buttonActions/setPodOrderPaymentIsInDefaultAction';


const SearchFilter = props => (
  <Filter {...props}>
    <TextInput label="Buyer User Id" source="buyerUserId" alwaysOn />
    <TextInput label="Seller User Id" source="sellerUserId" alwaysOn />
    <TextInput label="Reference Number" source="referenceNumber" alwaysOn />
    <TextInput label="Affiliate user id" source="affiliateUserId" alwaysOn />
  </Filter>
)

export const OrdersList = props => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="referenceNumber" label="Reference Number" />
      <TextField source="orderReceiveType" label="Receive Type" />
      <TextField source="paymentVariant" label="Payment" />
      <NumberField source="calculatedTotalCostMajor" label= "Amount Major"/>
      <NumberField source="deliveryCostMajor" label= "Delivery Major"/>

      <ReferenceField source="buyerPublicProfile.userUuid" reference="users" label="Buyer First Name" link={false}>
        <TextField source="firstName" />
      </ReferenceField>

      <ReferenceField source="buyerPublicProfile.userUuid" reference="users"  label="Buyer Last Name" link={false}>
        <TextField source="lastName" />
      </ReferenceField>

      <ReferenceField source="sellerPublicProfile.userUuid" reference="users" label="Seller First Name" link={false}>
        <TextField source="firstName" />
      </ReferenceField>

      <ReferenceField source="sellerPublicProfile.userUuid" reference="users"  label="Seller Last Name" link={false}>
        <TextField source="lastName" />
      </ReferenceField>

      <TextField source="status" label="Status" />
      <TextField source="paymentStatus" label="Payment Status" />
      <TextField source="statusHistory" label="Status History" />
      <TextField source="paymentStatusHistory" label="Payment History" />
      <TextField source="receiver" label="Receiver" />

      <FlagReceivedOrderStatusButton {...props} label="Confirm Order" />
      <CancelOrderButton {...props} label="Cancel Order" />
      <FlagPodOrderAsInDefaultButton {...props} label="Buyer Default" />
    </Datagrid>
  </List>
);

export const CancelOrderButton = props => {
  return (
    <TopToolbar>
      <Button label="Cancel" color="primary" onClick={
        (e) => {
          cancelOrdercustomAction(props.record.uuid, window.location.href)
        e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};

export const FlagPodOrderAsInDefaultButton = props => {
  return (
    <TopToolbar>
      <Button label="Buyer cant pay" color="primary" onClick={
        (e) => {
          flagPodOrderPaymentDefaultCustomAction(props.record.uuid, window.location.href)
          e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};

export const FlagReceivedOrderStatusButton = props => {
  return (
    <TopToolbar>
      <Button label="Order Received" color="primary" onClick={
        (e) => {
          recievedOrderStatus(props.record.uuid, window.location.href)
        e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};
