// import Button from '@material-ui/core/Button';
import { Button, Datagrid, DateField, Filter, List, NumberField, TextField, TextInput, TopToolbar } from 'react-admin';
import { markPriceMatrixOrderAsDeliveredAction } from './buttonActions/markPriceMatrixOrderAsDeliveredAction';
import { declinePriceMatrixCustomAction } from './buttonActions/setPriceMatrixasDecline';

const SearchFilter = props => (
  <Filter {...props}>
     <TextInput label="Qoute request Ref" source="qouteRequestRef" alwaysOn />
     <TextInput label="Buyer id" source="buyerUserId" alwaysOn />
  </Filter>
)

export const PriceMatrixList = props => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit" >
      <TextField source="id" label="Price Matrix id" link={false} />
      <TextField source="qouteRequestRef" label="Qoute request Ref" />
      <TextField source="buyerUserId" label="Buyer Id" />
      <TextField source="product.name" label="Product Name" />
      <TextField source="quantity" label= "Quantity"/>
      <NumberField source="productSellingPriceMajor" label= "Selling Price"/>
      <NumberField source="productCostPriceMajor" label= "Cost Price"/>
      <NumberField source="totalProductSellingPriceMajor" label= "Totoal Selling Price"/>
      <NumberField source="productMarginMajor" label= "Margin"/>
      <NumberField source="totlaMarginMajor" label= "Total Margin"/>
      <TextField source="status" label="Status" />
      <TextField source="statusHistory" label="Status History" />
      <DateField source="deliveryDate" label="Delivery Date" />
      <TextField source="deliveryAddress" label= "Delivery Address"/> 
      <DateField source="createdAt" />
      <MarkPriceMatrixOrderAsDeliveredButton {...props} label="Mark As Delivered" />
      <DeclinePriceMatrixButton {...props} label="Cancel" />
    </Datagrid>
  </List>
);

export const MarkPriceMatrixOrderAsDeliveredButton = props => {
  return (
    <TopToolbar>
      <Button label="Mark Order as Delivered" color="primary" onClick={
        (e) => {
          markPriceMatrixOrderAsDeliveredAction(props.record.id, window.location.href)
          e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};

export const DeclinePriceMatrixButton = props => {
  return (
    <TopToolbar>
      <Button label="Cancel" color="primary" onClick={
        (e) => {
          declinePriceMatrixCustomAction(props.record.id, window.location.href)
        e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};


