import logo from './logo.svg';
import './App.css';
import { 
  Admin, Resource, ListGuesser, List, Datagrid, ReferenceField, 
  TextField, NumberField, BooleanField, DateField, 
} from 'react-admin';


export const BrandsList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
    <TextField source="id" label="Brand Id" link={false} />
      <TextField source="name" label= "Category Name"/>
      <TextField source="productsCount" label= "Product Count "/>
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);


