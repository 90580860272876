import logo from './logo.svg';
import './App.css';
import { 
  Admin, Resource, ListGuesser, List, Datagrid, ReferenceField, 
  TextField, NumberField, BooleanField, DateField, UrlField, ImageField, EditButton,
  Edit, SimpleForm, TextInput, SelectInput, ReferenceInput, DateTimeInput, BooleanInput,
  Create, Filter, NumberInput
} from 'react-admin';


export const PromotionsList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" label = "ID"  link={false} />

      <TextField source="name" label= "Promotion Name"/>

      <NumberField source="percentage" label= "Percentage (%)" />

      <DateField source="endDate" label= "End date"/>
      <BooleanField source="isActive" label="Is Active" />

      <DateField source="createdAt" />
    </Datagrid>
  </List>
);


export const PromotionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <NumberInput source="percentage" />
      <TextInput source="categoryUuid" />
    </SimpleForm>
  </Create>
);
