import { Button, Datagrid, Filter, List, TextField, TextInput, TopToolbar } from 'react-admin';
import { declineQuoteRequestcustomAction } from './buttonActions/setQuoteRequestasDecline';

const SearchFilter = props => (
  <Filter {...props}>

<TextInput label="user Id" source="userId" alwaysOn />
<TextInput label="reference Number" source="referenceNumber" alwaysOn />
  </Filter>
)

export const qouteRequestList = props => (
  <List {...props} filters={<SearchFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />
      <TextField source="userId" label="Buyer User Id" />
      <TextField source="referenceNumber" label="Reference Number" />
      <TextField source="product.name" label="Product Name" />
      <TextField source="quantity" label="Quantity" />
      <TextField source="orderReceiveType" label="Order Receive Type" />
      <TextField source="status" label=" Status" />
      <DeclineQuoteRequestButton {...props} />
    </Datagrid>
  </List>
);


export const DeclineQuoteRequestButton = props => {
  return (
    <TopToolbar>
      <Button label="Cancel" color="primary" onClick={
        (e) => {
          declineQuoteRequestcustomAction(props.record.uuid, window.location.href)
        e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};




