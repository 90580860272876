import {
  Button, Datagrid, Filter, List, NumberField, TextField, TextInput, TopToolbar
} from 'react-admin';
import { confirmProcurement } from './buttonActions/confirmProcurement';



const SearchFilter = props => (
 <Filter {...props}>
   <TextInput label="account Id" source="accountId" alwaysOn />
 </Filter>
)


export const procurementList = props => (
 <List {...props} filters={<SearchFilter />}>
   <Datagrid rowClick="edit">
     <TextField source="id" label="Id" />
     <TextField source="accountId" label="Account Id" />
     <NumberField source="isProcessed" label= "Is Processed"/>
     <TextField source="createdAt" label="Submitted On" />
     <DownloadButton {...props}/>
     <ConfirmProcurementButton {...props}/>
   </Datagrid>
 </List>
);


export const DownloadButton = props => {
  const url = props.record.upload.url
  return (
    <TopToolbar>
      
      <Button label="Download Docs" color="primary" href={url} target="_blank" onClick={
        (e) => {
        e.stopPropagation()
        }
        } />
       
    </TopToolbar>
  )
};


export const ConfirmProcurementButton = props => {
  return (
    <TopToolbar>
      <Button label="Confirm" color="primary" onClick={
        (e) => {
          confirmProcurement(props.record.id, window.location.href)
        e.stopPropagation()
        }
        } />
    </TopToolbar>
  )
};



